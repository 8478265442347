import * as React from 'react';


import './orbits.scss';
import './pulse.scss';

import jenkins from '../../../images/jenkins.png';
import angular from '../../../images/angular.png';
import apache from '../../../images/apache.png';
import react from '../../../images/react.png';
import visualstudio from '../../../images/visualstudio.png';
import php from '../../../images/php.png';
import windows from '../../../images/windows.png';
import github from '../../../images/github.png';
import linux from '../../../images/linux.png';
import nginx from '../../../images/nginx.png';

export const Orbits = (args : any) => {

    const showExtraInfo = args.showExtraInfo;

    const getIcon = (brand : string) : any => {
        let element = null;

        switch(brand){
            case "jenkins":
                element = (<image x="6" y="10" height="30" href={jenkins}/>)
                break;
            case "angular":
                element = (<image x="2" y="6" width="40" height="40" href={(angular)}/>)
                break;
            case "apache":
                element = (<image x="2" y="4" width="40" height="40" href={(apache)}/>)
                break;
            case "react":
                element = (<image x="0" y="2" width="43" height="43" href={(react)}/>)
                break;
            case "dotnet":
                element = (<image x="-2" y="2" width="45" height="45" href={(visualstudio)}/>)
                break;
            case "php":
                element = (<image x="-2" y="2" width="45" height="45" href={(php)}/>)
                break;
            
            case "iis":
            case "windows":
                element = (<image x="8" y="12" width="25" height="25" href={windows}/>)
                break;
            case "github":
                element = (<image x="2" y="5" width="40" height="40" href={github}/>)
                break;
            case "linux":
                element = (<image x="1" y="2" width="45" height="45" href={linux}/>);
                break;
            case "nginx":
                element = (<image x="-3" y="0" width="50" height="50" href={nginx}/>)
                break;
        }

        return element;
    }

    const getSvgIcon = (brand : string) : any => {
        return (
            <svg style={{overflow:"visible"}} preserveAspectRatio="xMinYMin meet">
                <path className={brand} d="M19.05255888325765 1.4999999999999998Q21.650635094610966 0 24.24871130596428 1.4999999999999998L40.703193977868615 11Q43.30127018922193 12.5 43.30127018922193 15.5L43.30127018922193 34.5Q43.30127018922193 37.5 40.703193977868615 39L24.24871130596428 48.5Q21.650635094610966 50 19.05255888325765 48.5L2.598076211353316 39Q0 37.5 0 34.5L0 15.5Q0 12.5 2.598076211353316 11Z">
                </path>
                {getIcon(brand)}
            </svg>);
    }

    return(
        <div className="space">
                {
                // Sistemas Operativos: Linux, Windows
                }
                <div className="orbit" id="first" onClick={()=>{showExtraInfo('anillo-1')}}>
                    <div className="planet one">
                        {getSvgIcon('windows')}
                    </div>
                    <div className="planet two">
                        {getSvgIcon('linux')}
                    </div>
                </div>
                
                {
                // Tipos de Servidor: Apache, Nginx, IIS
                }
                <div className="orbit" id="second" onClick={()=>{showExtraInfo('anillo-2')}}>
                    <div className="planet one">
                        {getSvgIcon('iis')}
                    </div>

                    <div className="planet two">
                        {getSvgIcon('apache')}
                    </div>

                    <div className="planet three">
                        {getSvgIcon('nginx')}
                    </div>
                    
                </div>
                
                {
                // Lenguajes de programación: Php, .NET, React, Angular
                }
                <div className="orbit" id="third" onClick={()=>{showExtraInfo('anillo-3')}}>
                    <div className="planet one">
                        {getSvgIcon('react')}
                    </div>
                    <div className="planet two">
                        {getSvgIcon('php')}
                    </div>
                    <div className="planet three">
                        {getSvgIcon('dotnet')}
                    </div>
                    <div className="planet four">
                        {getSvgIcon('angular')}
                    </div>
                </div>

                {
                // Otros: github, jenkins
                }
                <div className="orbit" id="fourth" onClick={()=>{showExtraInfo('anillo-4')}}>
                    <div className="planet one">
                        {getSvgIcon('github')}
                    </div>
                    <div className="planet two">
                        {getSvgIcon('jenkins')}
                    </div>
                </div>
                
            </div>
    )
}