import React from 'react';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import "antd/dist/antd.css";


import { Header, WebDevelopment, GraphicDesign, Footer, WorkFlowDevelop, Jobs, Cookies} from './components';


function App() {
  return (
    <div className="app">
      <Header/>
        <Jobs/>
        <WebDevelopment/>
        <GraphicDesign/>
        <WorkFlowDevelop/>
      <Footer/>
      <Cookies />
    </div>
  );
}

export default App;
