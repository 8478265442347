import * as React from 'react';
import { Button, Col, Row } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';

import '../../i18n';
import { useTranslation } from 'react-i18next';

import './jobs.scss';

export const Jobs = () => {

    const { t } = useTranslation('jobs');

    const scrollTo = (id: string) : void => {
        var element = document.getElementById(id);

        if (element !== null && element !== undefined){
            var yPosition = element.offsetTop - 100;
            window.scrollTo(0, yPosition);
        }
    }

    const goPdf = (pdfName: string) : any => {
        window.open(pdfName, '_blank');
    }
    

    return(
        <div className="container section jobs" id="jobs">
            <div className="col-12">
                    <h2 className="title"> 
                        {t("ourProducts")}
                    </h2>
            </div>
            
            
            <div className="col-12 job-list">
                <div className="product-info">
                    <div className="shield">
                        <div className="triangle-bottomright"></div>
                    </div>
                    <div className="body">
                        <div className="sub-title">
                            {t('omni-club.product-name')}
                        </div>
                        <p className="description">
                            {ReactHtmlParser(t('omni-club.description'))}
                        </p>
                        <p className="call-to-action sub-title">
                            {t('omni-club.contact-us')}
                        </p>
                        <div className='actions'>
                        
                            <Button type="primary" className="contact" shape='round' onClick={(e)=>{e.preventDefault(); scrollTo('footer')}}>
                                {t('omni-club.buttons.yes')}    
                            </Button>
                            <Button onClick={()=>{goPdf('/pdf/brochure_omni_club.pdf');}} className="btn-call-to-action download-brochure" shape='round'><FilePdfOutlined /> 
                                {t('omni-club.buttons.brochure')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}