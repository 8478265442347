import * as React from 'react';

import { ContactForm } from '../contactform/contactform';

import '../../i18n';
import { useTranslation } from 'react-i18next';

import './footer.scss';

import logo from '../../images/omnicode.png';
import qrContact from '../../images/qr-wa-link.png'

export const Footer = () => {

    const { t } = useTranslation('footer');

    return (
        
        <div className="footer" id="footer">
            <div className="container section">
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-9">
                        <ContactForm/>
                    </div>
                    <div className="col-12 col-md-12 col-lg-3">
                        
                        <div className="col-12">
                            <p>
                                <img className="logo" src={logo} alt="We love omnicode.es"/>
                                
                                <small>
                                    {t('copyRight')}
                                </small>
                            </p>
                        </div>
                        <div className="col-12">
                            <img className="qr-contact" src={qrContact} alt="We love omnicode.es"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}