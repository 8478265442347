import * as React from 'react';


import '../../i18n';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGem, faLeaf, faLink, faCloud } from '@fortawesome/free-solid-svg-icons';

import './webdevelopment.scss';

export const WebDevelopment = () => {

    const { t } = useTranslation('webDevelopment');

    return (
        <div className="container section web-development" id="webDevelopment">
            <div className="row">
                <div className="col-12 col-lg-7">
                    <h2 className="title"> 
                        {t("title")}
                    </h2>
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="sub-title">
                                <FontAwesomeIcon className="fa-icon" icon={faGem}/>
                                {t("subtitles.webApps.title")}
                            </div>
                            <p className="text-box tab-50px">
                                {t("subtitles.webApps.description")}
                            </p>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="sub-title">
                                <FontAwesomeIcon className="fa-icon" icon={faLeaf}/>
                                {t("subtitles.microservices.title")}
                            </div>
                            <p className="text-box tab-50px">
                                {t("subtitles.microservices.description")}
                            </p>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="sub-title">
                                <FontAwesomeIcon className="fa-icon" icon={faLink}/>
                                {t("subtitles.webSites.title")}
                            </div>
                            <p className="text-box tab-50px">
                                {t("subtitles.webSites.description")}
                            </p>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="sub-title">
                                <FontAwesomeIcon className="fa-icon" icon={faCloud}/>
                                {t("subtitles.mobileApps.title")}
                            </div>
                            <p className="text-box tab-50px">
                                {t("subtitles.mobileApps.description")}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-5 section-image">

                </div>
            </div>
        </div>
    )
}