import React, { useState, useEffect, useLayoutEffect } from 'react';
import Menu from '../menu/menu';

import ReactHtmlParser from 'react-html-parser';

import '../../i18n';
import { useTranslation } from 'react-i18next';

import './header.scss';
import { Button } from 'antd';


export const Header = () => {

    const [scroll, setScroll] = useState({left:window.pageXOffset, top:window.pageYOffset, class:'fixed'})

    const { t } = useTranslation('header');

    useEffect(()=>{
        const handleScroll = () =>{
            setScroll({
                left: window.pageXOffset,
                top: window.pageYOffset,
                class: window.pageYOffset > 100 ? 'float' : 'fixed'
            })
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        }

        
    }, [window.pageXOffset, window.pageYOffset])

    const scrollTo = (id: string) : void => {
        var element = document.getElementById(id);

        if (element !== null && element !== undefined){
            var yPosition = element.offsetTop - 100;
            window.scrollTo(0, yPosition);
        }
    }

    return(
        <div className="header container-fluid">
            <div className="triangle-background-image" style={{backgroundImage: 'url(https://i.pinimg.com/originals/e7/d3/83/e7d383a9ddb7187adfe162110055b121.jpg)'}}></div>
        
            <div className="row">
                <div className="col-12">
                    <Menu class={scroll.class}/>
                </div>
                <div className="col-12" style={{marginTop: scroll.class == 'float' ? "100px" : "0"}}>
                    <h1>{t("hireUs")}</h1>
                    <p className="col-12 col-lg-8 offset-lg-2">
                           {ReactHtmlParser(t("hireUsMessage"))}
                    </p>
                    <Button className="get-started" type="primary" shape="round" onClick={(e)=>{e.preventDefault(); scrollTo('footer')}}>
                        {t("getStarted")}
                    </Button>
                </div>
            </div>
        </div>
    )
}



