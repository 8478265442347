import * as React from 'react';

import '../../i18n';
import { useTranslation } from 'react-i18next';

import './graphicdesign.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faFunnelDollar, faHashtag, faDraftingCompass } from '@fortawesome/free-solid-svg-icons';

export const GraphicDesign = () => {

    const { t } = useTranslation('graphicdesign');

    return (
        <div className="container section graphic-design" id="graphicDesign">
            <div className="row">
                <div className="col-12 col-lg-5 section-image">
                </div>
                <div className="col-12 col-lg-7">
                    <h2 className="title"> 
                        {t('title')}
                    </h2>
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="sub-title">
                                <FontAwesomeIcon className="fa-icon" icon={faDraftingCompass}/>
                                {t('printedDesign')}
                            </div>
                            <p className="text-box tab-50px">
                                {t('printedDesignMessage')}
                            </p>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="sub-title">
                                <FontAwesomeIcon className="fa-icon" icon={faBuilding}/>
                                {t("corporateDesign")}
                            </div>
                            <p className="text-box tab-50px">
                                {t("corporateDesignMessage")}
                            </p>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="sub-title">
                                <FontAwesomeIcon className="fa-icon" icon={faFunnelDollar}/>
                                {t("marketingOnline")}
                            </div>
                            <p className="text-box tab-50px">
                                {t("marketingOnlineMessage")}
                            </p>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="sub-title">
                                <FontAwesomeIcon className="fa-icon" icon={faHashtag}/>
                                {t("socialNetworks")}
                            </div>
                            <p className="text-box tab-50px">
                                {t("socialNetworksMessage")}
                            </p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}