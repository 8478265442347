import * as React from 'react';
import { Button } from 'antd';

import '../../../../i18n';
import { useTranslation } from 'react-i18next';

export const RingOneInfo = () => {

    const { t } = useTranslation('orbitario');

    return (
        <div className="work-flow-main-info">
            <h1>{t("operatingSystem.title")}</h1>
            <h3>{t("operatingSystem.subTitle")}</h3>
        </div>
    );
}