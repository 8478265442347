import * as React from 'react';

import { useState, useEffect } from 'react';

import '../../i18n';
import { useTranslation } from 'react-i18next';

import { Orbits } from './orbits/orbits';
import { RingOneInfo, RingTwoInfo, RingThreeInfo, RingFourInfo } from './orbitinfo';
import { Button } from 'antd';


import './workflow.scss';

export const WorkFlowDevelop = () => {

    const { t } = useTranslation('orbitario');

    const scrollTo = (id: string) : void => {
        var element = document.getElementById(id);

        if (element !== null && element !== undefined){
            var yPosition = element.offsetTop - 100;
            window.scrollTo(0, yPosition);
        }
    }

    const getNoInfo = () => {
        return (
            <div className="work-flow-main-info">
                <h1>{t('grantedSuccess')}</h1>
                <h3>{t('withOmniCode')}</h3>
                <p>
                    {t('mainMessage')}
                </p>

                <p>
                    {t('moreInfo')}

                    
                </p>
            </div>
        ) 
    }

    const getExtraInfo = (_extraInfo : string) : any => {
        let item : any = null;

        switch(_extraInfo){
            default:
            case "no-info":
                item = getNoInfo();
                break;
            case "anillo-1":
                item = <RingOneInfo/>;
                break;
            case "anillo-2":
                item = <RingTwoInfo/>;
                break;
            case "anillo-3":
                item = <RingThreeInfo/>;
                break;
            case "anillo-4":
                item = <RingFourInfo/>;
                break;
        }

        return item;
    }

    const [extraInfo, setExtraInfo] = useState(getExtraInfo("no-info"));

    const showExtraInfo = (_extraInfo : string) => {
        setExtraInfo(getExtraInfo(_extraInfo));
    }

    useEffect(()=>{
        // Workaround
        setTimeout(()=>{setExtraInfo(getExtraInfo("no-info"));}, 500);
    }, []);

    return (
        <div className="container section our-workflow" id="workFlow">
            <div className="row">
                <div className="col-12 col-lg-6 extra-info">
                    <div className="row">
                        <div className="col-12 ">
                            {extraInfo}
                            <Button className="get-started" type="primary" shape="round" onClick={(e)=>{e.preventDefault(); scrollTo('footer')}}>
                                {t('shallWeStart')}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 section-orbits">
                    <Orbits showExtraInfo={showExtraInfo}/>
                </div>
            </div>
        </div>
    )
}