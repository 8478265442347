import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';


import './cookies.scss'

interface CookiesProps{}


export const Cookies = (props: CookiesProps) : JSX.Element =>{

    const { t } = useTranslation('cookies');

    const [cookiesIsAccepted, setCookiesIsAccepted] = useState<boolean>(false);
    const checkCookies = () : void => {

        var cookies = window.localStorage.getItem("omnicode-cookies-acceptance");

        if (cookies){
            setCookiesIsAccepted(true);
        }else{
            setCookiesIsAccepted(false);
        }
    }

    const acceptCookies = () : void => {

        window.localStorage.setItem("omnicode-cookies-acceptance", Date.now().toString());
        setCookiesIsAccepted(true);
    }

    useEffect(()=>{
        checkCookies();
    },[])

    return (
        <>
            {cookiesIsAccepted ? <></> : 
                <div className="cookies"> 
                    <p className='sub-title'>Omnicode</p>
                    <p>
                        {ReactHtmlParser(t('acceptance-text'))}
                        <div className="actions">
                            <Button type="primary" onClick={acceptCookies}>
                                {t('buttons.accept')}
                            </Button>
                            <Button type="danger">
                                <a href="https://www.google.es">
                                    {t('buttons.reject')}
                                </a>
                            </Button>
                        </div>
                    </p>
                </div>}
        </>
        
    )
}
