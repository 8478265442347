import * as React from 'react';
import { Button } from 'antd';

import '../../../../i18n';
import { useTranslation } from 'react-i18next'; 

export const RingThreeInfo = () => {

    const { t } = useTranslation('orbitario');

    return (
        <div className="work-flow-main-info">
                <h1>{t('programmingLanguages.title')}</h1>
                <h3>{t('programmingLanguages.subTitle')}</h3>
                <p>
                    {t('programmingLanguages.text1')}
                </p>
                <p>
                    {t('programmingLanguages.text2')}              
                </p>
            </div>
    );
}