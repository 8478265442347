import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';

import { Alert, Button, Checkbox, Form, Input } from 'antd'

import { WhatsAppOutlined, SendOutlined, LoadingOutlined } from '@ant-design/icons'


import '../../i18n';
import { useTranslation } from 'react-i18next';

import './contactform.scss';

export const ContactForm = () => {
    const [form] = Form.useForm();


    const { t } = useTranslation('footer');


    const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = React.useState<boolean>(false);
    const acceptContact = (v: any) : void => {
        console.log("V", v.target.checked);
        setAcceptedTermsAndConditions(v.target.checked);
    }

    const [sendingForm, setSendingForm] = React.useState<boolean>(false);
    const [resultMessage, setResultMessage] = React.useState<any>(<></>)
    const onFinish = () => {

        setSendingForm(true);
        fetch('https://api.clubes.omnicode.es/v1/Communications/SendEmailFromContactForm', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(form.getFieldsValue())
        })
        .then((response: any)=>{
            if (response && response.status === 200){
                form.resetFields();
                setAcceptedTermsAndConditions(false);
                setResultMessage(<Alert message={t('contact-form.submit-ok')} type="success" showIcon />)
            }else{
                setResultMessage(<Alert message={t('contact-form.submit-warning')} type="warning" showIcon />)
            }
        })
        .catch((errorData: any)=>{
            console.log("Error", errorData);
            setResultMessage(<Alert message={t('contact-form.submit-ko')} type="error" showIcon />)

        })
        .finally(()=>{
            setSendingForm(false);
        })
        
    }

    return (
        <div className="contact-us">
            <div className="row">
                <div className="col-12">
                    <h2 className="title"> 
                        {t('contactUs')}
                    </h2>
                        <h3>
                            {t('title')}
                            <small>
                                {t('subTitle')}
                            </small>
                        </h3>
                        <div className="contact-form">
                            <Form form={form} layout="vertical" onFinish={onFinish}>
                            <Form.Item
                                    label={t('contact-form.your-email')}
                                    name="email"
                                    rules={[{ required: true, message: t('contact-form.errors.required') }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={t('contact-form.your-phone')}
                                    help={t('contact-form.phone-help')}
                                    name="phone"
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={t('contact-form.your-name')}
                                    name="name"
                                    rules={[{ required: true, message: t('contact-form.errors.required') }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={t('contact-form.subject')}
                                    name="subject"
                                    rules={[{ required: true, message: t('contact-form.errors.required') }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={t('contact-form.body')}
                                    name="body"
                                    rules={[{ required: true, message: t('contact-form.errors.required') }]}
                                >
                                    <Input.TextArea rows={5}/>
                                </Form.Item>
                                {resultMessage}
                                <small>
                                    <Checkbox onClick={acceptContact}>{t('contact-form.accept-terms')}</Checkbox>
                                </small>
                                <small className="alternative-contacts">
                                    {ReactHtmlParser(t("alternative-contacts.a"))} <WhatsAppOutlined className="whatsapp-icon"/>
                                    {ReactHtmlParser(t("alternative-contacts.b"))}&nbsp;
                                    {ReactHtmlParser(t("alternative-contacts.c"))}<a href="mailto:info@omnicode.es">we.love@omnicode.es</a> 
                                </small>
                                <div className="btn-actions">
                                    <Button disabled={!acceptedTermsAndConditions} htmlType='submit' type="primary" className="send" shape='round'>
                                        {t('contact-form.btn-send')} {sendingForm ? <LoadingOutlined /> : <SendOutlined />    }
                                    </Button>
                                </div>
                            </Form>
                        </div>
                        
                </div>
            </div>
        </div>
    )
}