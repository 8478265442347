import * as React from 'react';
import { Button } from 'antd';

import '../../../../i18n';
import { useTranslation } from 'react-i18next'; 

export const RingFourInfo = () => {

    const { t } = useTranslation('orbitario');

    return (
        <div className="work-flow-main-info">
                <h1>{t('continuousIntegration.title')}</h1>
                <h3>{t('continuousIntegration.subTitle')}</h3>
                <p>
                    {t('continuousIntegration.text')}
                </p>
            </div>
    );
}