import React, { useState } from 'react';
import './menu.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faLink, faCloud } from '@fortawesome/free-solid-svg-icons';

import '../../i18n';
import { useTranslation } from 'react-i18next';

import weLoveOmniCode from '../../images/we_love_omnicode.png';

const Menu = (props : any) =>{

    const [collapsed, setCollapsed] = useState(false); 
    const [menuVisible, setMenuVisible] = useState(false);

    const { t } = useTranslation('menu');

    const scrollTo = (id: string) : void => {
        var element = document.getElementById(id);

        if (element !== null && element !== undefined){
            var yPosition = element.offsetTop - 100;
            window.scrollTo(0, yPosition);
        }
    }

    return(
        <div className={`menu ${collapsed ? 'collapsed' : ''} ${props.class}`}>
            <img className="logo" src={weLoveOmniCode} alt="We Love Omnicode"/>
            <FontAwesomeIcon className={`fa-icon show-mobile-menu-btn ${menuVisible ? 'hidden' : ''}`} icon={faBars}  onClick={()=>{setMenuVisible(!menuVisible)}}/>
            <ul className="links">
                <li>
                    <a href="#jobs" onClick={(e)=>{e.preventDefault(); scrollTo('jobs')}}>
                        {t('apps')}
                    </a>
                </li>
                <li>
                    <a href="#webDevelopment" onClick={(e)=>{e.preventDefault(); scrollTo('webDevelopment')}}>
                        {t('webDevelopment')}
                    </a>
                </li>
                <li>
                    <a href="#graphicDesign" onClick={(e)=>{e.preventDefault(); scrollTo('graphicDesign')}}>
                        {t('graphicDesign')}
                    </a>
                </li>
                <li>
                    <a href="#workFlow" onClick={(e)=>{e.preventDefault(); scrollTo('workFlow')}}>
                        {t('workFlow')}
                    </a>
                </li>
                <li>
                    <a href="#footer" onClick={(e)=>{e.preventDefault(); scrollTo('footer')}}>
                        {t('contact')}
                    </a>
                </li>
            </ul>
            <ul className={`mobile-links ${menuVisible ? '' : 'hidden'}`}>
                <FontAwesomeIcon className="fa-icon show-mobile-menu-btn times" icon={faTimes}  onClick={()=>{setMenuVisible(!menuVisible)}}/>
                <li>
                    <a className="sub-title" href="#jobs" onClick={(e)=>{e.preventDefault(); scrollTo('jobs')}}>
                        {t('apps')}
                    </a>
                </li>
                <li>
                    <a className="sub-title" href="#webDevelopment" onClick={(e)=>{e.preventDefault(); scrollTo('webDevelopment')}}>
                        {t('webDevelopment')}
                    </a>
                </li>
                <li>
                    <a className="sub-title" href="#graphicDesign" onClick={(e)=>{e.preventDefault(); scrollTo('graphicDesign')}}>
                        {t('graphicDesign')}
                    </a>
                </li>
                <li>
                    <a className="sub-title" href="#workFlow" onClick={(e)=>{e.preventDefault(); scrollTo('workFlow')}}>
                        {t('workFlow')}
                    </a>
                </li>
                <li>
                    <a className="sub-title" href="#footer" onClick={(e)=>{e.preventDefault(); scrollTo('footer')}}>
                        {t('contact')}
                    </a>
                </li>
            </ul>
        </div>
    )

}


export default Menu;
